.h-wrap{
    background: var(--black);
}


.blue-circle{
    height: 1rem;
    width: 1rem;
    background: rgb(65, 65, 125);
    border-radius: 999px;
    position:absolute;
    left: 20%;
    top: 1.6%;
    z-index:0;
}
.t-head{
    color: #d6d7e2;
    z-index: 2;
    position: relative;
}

.h-container{
    justify-content: space-between;
    padding-top: 1pc ;
    padding-bottom: 1rem;
    color: var(--secondary);
}
.h-menu{
    gap: 2rem;
}
.h-menu:hover{
    cursor: pointer;
}

.h-menu:hover{
    cursor:pointer;
}
.menu-icon{
    display: none;
}
@media (max-width:768px){
 .menu-icon{
         display:block
 }
 .h-menu{
    color:var(--black);
    position:absolute;
    z-index: 2;
    top:3rem;
    right:4rem;
    background: white;
    flex-direction: column;
    font-weight: 500;
    gap:2rem;
    padding:3rem;
    border-radius: 10px;
    align-items: flex-start;
    box-shadow: 0px 4 px 4px rgba(0, 0, 0, 0.05);
    transition: all 300ms ease-in-out
 }
}