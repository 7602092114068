.v-container .image-container{
    border: 8px solid rgba(232 232 232 / 93%);
}
.v-container div{
    flex:1
}
.v-right{
    gap: 0.5rem;
}
.accordion{
    margin-top: 2rem;
    border:none;
}


.accordionItem{
    background: white;
    border: 0.8px solid rgba(128,128,128,0.143);
    border-radius:8px;
    overflow: hidden;
    margin-bottom: 20px;
}
.accordionItem.expanded{
     box-shadow: var(--shadow);
     border-radius: 6px  ;
}

.accordionButton{
    background: white;
    padding: 1rem ;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
}
.icon{
    
    padding-top: 10px;
    padding-bottom: 10px;
  
    background: #eeeeff;
    border-radius: 5px;
}
.icon svg{
    fill : var(--blue);
   
}

.accordionItem{
   justify-content: center; 
   text-align: center;
   max-width: 650px;
}
.accordionButton .primaryText{
    font-size: 1.1rem;
   padding: 20px;
    width:450px;
    text-align: center;
}
@media (max-width: 1024px){
    .v-container{
        flex-direction: column;
    }
    .icon{
        max-width: 15px;
        padding: auto;
    }
}
@media (max-width: 760px){
    .accordionButton .primaryText{
        font-size: 0.8rem;
        width:auto;
       Min-width: 100px;
       max-width: 450px;
    }
    .icon{
        max-width: 10px;
        padding: auto;
    }
}
