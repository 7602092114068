.f-container{
    justify-content: space-between;
}

.f-left{
    gap:1rem;
}
.f-menu{
    gap:1.5rem;
    margin-top:1.5 rem;
    font-weight: 500;
}

@media(max-width: 768px){
    .f-container{
        justify-content: center;
    }
    .f-container div{
        align-items: center;
        text-align: center;
    }
}